import React from 'react';
import { graphql, HeadFC, HeadProps, PageProps } from "gatsby";
import { Product as ProductSchema } from "models/schemas/product";
import { Layout } from "layouts/layout";
import { Box, Button, Container, Grid, Link } from "@mui/material";
import { markdownOptions } from "components/options/markdown-options";
import Markdown from "markdown-to-jsx";
import { ProcessCard } from "content/cards/process-card";
import { PageMetadata } from "components/layout/page-metadata";

export interface ProductProps {
    product: ProductSchema;
}

const Product: React.FC<PageProps<ProductProps>> = ({ data }) => {

    const { product } = data;
    
    console.log(product)
    
    return (
        <Layout>
            <Container maxWidth={'lg'}>
                <Grid container spacing={10} justifyContent={"center"} mt={5}>
                    <Grid item container justifyContent={"center"} md={10}>
                        {product.wordmark &&
                            <Grid item md={8}>
                                <img width={"100%"} style={{maxHeight: "250px", objectFit: "contain"}} src={product.wordmark?.url} alt={product.wordmark.alt}/>
                            </Grid>
                        }
                    </Grid>
                    <Grid item container md={10}>
                        <Grid item xs={12}>
                            <Markdown options={markdownOptions}>
                                {product.introduction.markdown}
                            </Markdown>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} position={"relative"}>
                        <Box className={"radial-gradient"}/>
                        {product.processes.map((process, index) => (
                            <Grid item md={6} lg={4} key={index}>
                                <ProcessCard
                                    process={process}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container justifyContent={"center"} md={10} spacing={4}>
                        <Grid item xs={12}>
                            <Markdown options={markdownOptions}>
                                {product.furthermore.markdown}
                            </Markdown>
                        </Grid>
                        {product.link ?
                            <Grid item>
                                <Button LinkComponent={Link} href={product.link.url} >Visit {product.link.label} Website</Button>
                            </Grid> :
                            <Grid item>
                                <Button className={"animated-gradient"} LinkComponent={Link} href={`mailto:enquiries@stackly.ltd?subject=We'd love to find out more about ${product.name}!`} size={"large"}>
                                    Enquire about <span style={{fontWeight: "bold", marginLeft: "5px"}}>{product.name}</span>
                                </Button>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}
export default Product;

export const Head: HeadFC = (props: HeadProps<any>) => (
    <PageMetadata location={props.location.pathname} metadata={props.data.product.page.metadata}/>
)

export const query = graphql`
    query($id: String!) {
        product: graphCmsProduct(id: { eq: $id }) {
            icon {
                url
                alt
            }
            wordmark {
                url
                alt
            }
            name
            summary
            introduction {
                markdown
            }
            processes {
              title
              description
              items
            }
            furthermore {
                markdown
            }
            link{
                label
                url
                isInternal
            }
            page {
                link {
                    label
                    url
                }
                metadata {
                    title
                    description
                    thumbnail {
                      url
                    }
                    embedImage {
                      url
                    }
                    jsonLD
                }
                banner{
                    url
                    alt
                }
            }
        }
    }`